import { createApp } from "vue";
import {
    Quasar,
    Notify,
    QLayout,
    QSpinnerDots,
    QDialog,
    QCard,
    QIcon,
    QBtn,
    QInput,
    QSelect,
    QCheckbox,
    QPopupProxy,
    QDate,
    QPageContainer,
    QHeader,
    QFooter,
    QMenu,
    QItem,
    QTree,
    QLinearProgress,
    QScrollArea,
    QTooltip,
    QOptionGroup,
} from "quasar";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import theme from "./theme";
import { registerModules } from "./register-modules";

import AppLayout from "./layouts/AppLayout.vue";
import palveluhakuModule from "./modules/palveluhaku";
import valvontatiedotModule from "./modules/valvontatiedot";
import organisationsModule from "./modules/organisations";
import palveluntuottajaModule from "./modules/palveluntuottaja";
import puitesopimuksetModule from "./modules/puitesopimukset";
import kilpailutusModule from "./modules/kilpailutus";
import frontpageModule from "./modules/frontpage";
import saavutettavuusselosteModule from "./modules/saavutettavuusseloste";
import valvontakalenteriModule from "./modules/valvontakalenteri";

registerModules({
    palveluhaku: palveluhakuModule,
    valvontatiedot: valvontatiedotModule,
    organisations: organisationsModule,
    palveluntuottaja: palveluntuottajaModule,
    puitesopimukset: puitesopimuksetModule,
    kilpailutus: kilpailutusModule,
    frontpage: frontpageModule,
    valvontakalenteri: valvontakalenteriModule,
    saavutettavuusseloste: saavutettavuusselosteModule,
});

const quasarUserOptions = {
    components: {
        QLayout,
        QSpinnerDots,
        QDialog,
        QCard,
        QIcon,
        QBtn,
        QInput,
        QSelect,
        QCheckbox,
        QPopupProxy,
        QDate,
        QPageContainer,
        QHeader,
        QFooter,
        QMenu,
        QItem,
        QTree,
        QLinearProgress,
        QScrollArea,
        QTooltip,
        QOptionGroup,
    },
    plugins: {
        Notify,
    },
    ...theme,
};

createApp(App)
    .use(router)
    .use(store)
    .use(Quasar, quasarUserOptions)
    .component("AppLayout", AppLayout)
    .mount("#app");
